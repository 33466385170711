import moment from 'moment';
import * as y from 'yup';

import { validations } from '../../../../../helpers/validations';

import { healthOperatorsControl } from './selectOptions';

const envListValue = process.env.REACT_APP_NUMBER_CARD || '';

const idsList = envListValue.split(',');

interface IGenerateSchemaProps {
  showExclusiveFields: boolean;
  translateNationality: (value: string) => string;
}
export const generateSchema = ({
  showExclusiveFields,
  translateNationality,
}: IGenerateSchemaProps) => {
  return y.object().shape({
    encounterHealthOperator: showExclusiveFields
      ? y
          .string()
          .min(2, 'No mínimo 2 caracteres')
          .required('Campo obrigatório')
      : y.string().notRequired().nullable(),
    encounterHealthOperatorName: y.string().when('encounterHealthOperator', {
      is: 'Outros',
      then: y
        .string()
        .max(64, 'O nome da operadora deve ter no máximo 64 caracteres')
        .required('Campo obrigatório'),
      otherwise: y.string().notRequired().nullable(),
    }),
    encounterPlan: showExclusiveFields
      ? y
          .string()
          // Regra da operadora não exigir plano
          .when('encounterHealthOperator', {
            is: (value: string) => {
              if (!value || typeof value !== 'string') return false;

              const findInList = healthOperatorsControl.find(
                (item) => item.label === value
              );

              return findInList?.isPlanRequired === false;
            },
            then: y.string().notRequired().nullable(),
            otherwise: y
              .string()
              .min(2, 'No mínimo 2 caracteres')
              .required('Campo obrigatório'),
          })
      : y.string().notRequired().nullable(),
    isPatientEligible: showExclusiveFields
      ? y.string().min(1, 'Campo obrigatório').required('Campo obrigatório')
      : y.string().notRequired().nullable(),
    purchaseCode: showExclusiveFields
      ? y.string().when('isPatientEligible', {
          is: 'não',
          then: y
            .string()
            .max(36, 'O código deve ter no máximo 36 caracteres')
            .required('Campo obrigatório'),
          otherwise: y.string().notRequired(),
        })
      : y.string().notRequired().nullable(),
    convenio: y.string().required('Campo obrigatório.'),
    numberCard: y
      .string()
      .when('convenio', {
        is: (value: string) => {
          const convenioId: string = String(value || '').split('|')[0];

          return idsList.includes(convenioId);
        },
        then: y.string().required('Insira o número de carteirinha'),
        otherwise: y.string().notRequired().nullable(),
      })
      // Regra da operadora exigir carteirinha com exatos 11 chars
      .when('encounterHealthOperator', {
        is: (value: string) => {
          if (!value || typeof value !== 'string') return false;

          const findInList = healthOperatorsControl.find(
            (item) => item.label === value
          );

          return findInList?.isNumberCardRequired;
        },
        then: y
          .string()
          .required('Insira o número de carteirinha')
          .min(6, 'O número de carteirinha deve ter no mínimo 6 caracteres')
          .max(36, 'O número de carteirinha deve ter no máximo 36 caracteres'),
        otherwise: y.string().notRequired().nullable(),
      }),
    name: y
      .string()
      .trim()
      .min(2, 'No mínimo 2 caracteres')
      .matches(/^[aA-zZ\s\u00C0-\u00FF]+$/, {
        message: 'Caracteres não permitidos.',
      })
      .required('Campo obrigatório.'),
    lastName: y
      .string()
      .trim()
      .min(2, 'No mínimo 2 caracteres')
      .matches(/^[aA-zZ\s\u00C0-\u00FF]+$/, {
        message: 'Caracteres não permitidos.',
      })
      .required('Campo obrigatório.'),
    socialName: y
      .string()
      .when('checkSocialName', {
        is: true,
        then: y.string().required('Campo obrigatório.'),
      })
      .matches(/^[aA-zZ\s\u00C0-\u00FF]+$/, {
        message: 'Caracteres não permitidos.',
      }),
    gender: y.string().required('Campo obrigatório.'),
    birthDate: y
      .string()
      .required('Campo obrigatório.')
      .test('birthDate', 'Escolha uma data de nascimento válida', (value) => {
        let formDate;
        let actualDate = new Date(moment().utc().format('YYYY-MM-DD'));

        const parsedValue = String(value || '');

        if (parsedValue.length > 9 && moment(value, 'DD/MM/YYYY').isValid()) {
          formDate = new Date(
            moment(value, 'DD/MM/YYYY').utc().format('YYYY-MM-DD')
          );
        } else {
          formDate = new Date(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'));
        }

        if (parsedValue.length < 10) {
          return false;
        }

        return actualDate >= formDate;
      }),
    nationality: y.string().required('Campo obrigatório.'),
    document: y
      .string()
      .trim()
      .max(17, 'Número máximo de caracteres')
      .test('document', 'CPF inválido.', (document, context) => {
        if (
          !document ||
          context.parent.edit === true ||
          translateNationality(context.parent.nationality) === 'E'
        ) {
          return true;
        }

        return validations.validarCPF(document);
      }),
      country: y.string().test('country','Campo obrigatório.',(value,context)=>{
        if(context.parent.idFila !== 58){
          return y.string().required('Campo obrigatório.').isValidSync(value)
        }
       return true
      }),
    streetCep: y.string(),
    phone: y.string().trim().required('Campo obrigatório.'),
    email: y.string().email().required('Campo obrigatório.'),
    disruptiveBehavior: y.string(),
    urgencyAttendance: y.string(),
    emailSecondary: y.string(),
  });
};
