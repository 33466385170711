import { useDispatch } from 'react-redux';
import { acionamentoActionsVonage } from '../../../actions';
import CustomButton from '../../CustomButtom';
import { IconButton } from '../QueuePage/styles';
import Video from '../../../icons/video.svg';
import * as S from './styles';

const VideoConferenciaButton = ({
  data,
  setInAttendance,
  setLoading,
  setSessionIdState,
  setDocumentState,
  pushCall,
  setPushCallLoading,
  setPushCallTitle,
  setPushCallMessage,
  setPushCallModal,
}) => {
  const dispatch = useDispatch();

  const updatedTime = () => {
    let replacedDate = new Date().toISOString();
    return replacedDate;
  };

  const handleAttendance = async (data, setInAttendance) => {
    try {
      setLoading(true);
      const editionTime = updatedTime();
      dispatch(
        acionamentoActionsVonage.attendanceAcionamentos(
          { ...data, editionTime },
          '',
          setInAttendance
        )
      );

      setSessionIdState(data.idAttendanceVonage);
      setDocumentState(data.document);

      const pushCallInput = { document: data?.document };

      process.env.REACT_APP_EXECUTE_PUSH_CALL === 'true' &&
        (await pushCall(pushCallInput));
      setPushCallLoading(true);
      setPushCallTitle('Ligando');
      setPushCallMessage(
        'Paciente em segundo plano, estamos ligando para o paciente.'
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (
        e.response.data.message === 'O paciente encontra-se em primeiro plano.'
      ) {
        return;
      }

      setPushCallTitle('Erro ao contactar o paciente');
      setPushCallMessage(`Deseja tentar novamente?`);
      setPushCallLoading(false);
      setPushCallModal(true);
    }
  };

  return (
    <S.ContainerButton>
      <CustomButton
        onClick={() => {
          handleAttendance(data, setInAttendance);
        }}
        size={'large'}
        typeButtom={'green'}
      >
        <IconButton src={Video} alt='videoconferência' />{' '}
        <span>Videoconferência</span>
      </CustomButton>
    </S.ContainerButton>
  );
};

export default VideoConferenciaButton;
