import React from 'react';

import {
  Modal,
  ModalOverlay,
  CloseDiv,
  Close,
  InnerLayer,
  InnerContent,
  ChildrenContent,
  TitleModal,
  SubtitleModal,
  ContentButtons,
  ContainerButton,
  TitleContent,
} from './styles';
import CloseIcon from './Icon/close.svg';
import CustomButton from '../CustomButton';

export default function ModalComponent({
  open,
  handleClose,
  handleCopy,
  title,
  subtitle,
  children,
  withSubtitle,
  withClose,
  withActions,
  handleConfirm,
  typeButtomConfirm,
  typeButtomCancel,
  typeButtomCopy,
  labelCancel,
  labelCopy,
  labelOk,
  width,
  height,
}) {
  return (
    <>
      {open && (
        <ModalOverlay>
          <Modal width={width} height={height}>
            <InnerLayer>
              <InnerContent>
                <TitleContent>
                  <TitleModal className='rubik-medium'>{title}</TitleModal>
                  {withClose && (
                    <CloseDiv>
                      <Close src={CloseIcon} onClick={handleClose} />
                    </CloseDiv>
                  )}
                </TitleContent>
                {withSubtitle && <SubtitleModal>{subtitle}</SubtitleModal>}
                <ChildrenContent>{children}</ChildrenContent>
                {withActions && (
                  <ContentButtons>
                    {typeButtomCancel && labelCancel && (
                      <ContainerButton>
                        <CustomButton
                          onClick={handleClose}
                          size={'medium'}
                          typeButtom={typeButtomConfirm}
                          width={'100%'}
                        >
                          {labelCancel}
                        </CustomButton>
                      </ContainerButton>
                    )}

                    {labelCopy && handleCopy && (
                      <ContainerButton>
                        <CustomButton
                          onClick={handleCopy}
                          typeButtom={typeButtomCopy}
                          size='medium'
                          width={'100%'}
                        >
                          {labelCopy}
                        </CustomButton>
                      </ContainerButton>
                    )}

                    {labelOk && handleConfirm &&(
                    <ContainerButton>
                      <CustomButton
                        onClick={handleConfirm}
                        typeButtom={typeButtomCancel}
                        size='medium'
                        width={'100%'}
                      >
                        {labelOk}
                      </CustomButton>
                    </ContainerButton>
                    )}
                  </ContentButtons>
                )}
              </InnerContent>
            </InnerLayer>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
}
